:root {
    --title-color: #122a11;
    --card-bg: white;
    --catalog-bg: #f6faf2;
    --header-bg: #282c34;
    --logo-green: #589f2c;
    --light-text: #e5ffec;
    --sticker-bg: #c7eca1;
    --sticker-text: #122a11;
    --sticker-bg-piment: #f81630;
    --sticker-text-piment: #f8dbdb;
    --sticker-bg-aubergine: #6a5b91;
    --sticker-text-aubergine: #d9d3ec;
    --sticker-bg-tomate-cerise: #7E0021;
    --sticker-text-tomate-cerise: #f3dddd;
    --sticker-bg-tomate-rouge: #FF807F;
    --sticker-text-tomate-rouge: #4b4f8c;
    --sticker-bg-tomate-blanche: #f2f283;
    --sticker-text-tomate-blanche: #195117;
    --sticker-bg-tomate-foncee: #996633;
    --sticker-text-tomate-foncee: #d6dcd9;
    --sticker-bg-tomate-verte: #66CC01;
    --sticker-text-tomate-verte: #124f10;
    --sticker-bg-tomate-multicolore: #729FCF;
    --sticker-text-tomate-multicolore: #123779;
    --sticker-bg-courgette: #c7eca1;
    --sticker-text-courgette: #122a11;
    --sticker-bg-concombre: #c7eca1;
    --sticker-text-concombre: #122a11;
    --sticker-bg-aromatiques: #c7eca1;
    --sticker-text-aromatiques: #122a11;

    --contact-bg: #fff;
    --contact-color: #07631f;

    --mobile: 576px;
    --tabletPortrait: 768px;
    --tabletLandscape: 992px;
    --desktop: 1200px;
}

body {
    font-size: calc(10px + 2vmin);
}

.category-bg-01 {
    background-color: var(--sticker-bg-piment);
}

.category-text-01 {
    color: var(--sticker-text-piment);
}

.category-01 {
    background-color: var(--sticker-bg-piment);
    color: var(--sticker-text-piment);
}

.category-bg-02 {
    background-color: var(--sticker-bg-aubergine);
}

.category-text-02 {
    color: var(--sticker-text-aubergine);
}

.category-02 {
    background-color: var(--sticker-bg-aubergine);
    color: var(--sticker-text-aubergine);
}

.category-bg-03 {
    background-color: var(--sticker-bg-tomate-cerise);
}

.category-text-03 {
    color: var(--sticker-text-tomate-cerise);
}

.category-03 {
    background-color: var(--sticker-bg-tomate-cerise);
    color: var(--sticker-text-tomate-cerise);
}

.category-bg-04 {
    background-color: var(--sticker-bg-tomate-rouge);
}

.category-text-04 {
    color: var(--sticker-text-tomate-rouge);
}

.category-04 {
    background-color: var(--sticker-bg-tomate-rouge);
    color: var(--sticker-text-tomate-rouge);
}

.category-bg-05 {
    background-color: var(--sticker-bg-tomate-blanche);
}

.category-text-05 {
    color: var(--sticker-text-tomate-blanche);
}

.category-05 {
    background-color: var(--sticker-bg-tomate-blanche);
    color: var(--sticker-text-tomate-blanche);
}

.category-bg-06 {
    background-color: var(--sticker-bg-tomate-foncee);
}

.category-text-06 {
    color: var(--sticker-text-tomate-foncee);
}

.category-06 {
    background-color: var(--sticker-bg-tomate-foncee);
    color: var(--sticker-text-tomate-foncee);
}

.category-bg-07 {
    background-color: var(--sticker-bg-tomate-verte);
}

.category-text-07 {
    color: var(--sticker-text-tomate-verte);
}

.category-07 {
    background-color: var(--sticker-bg-tomate-verte);
    color: var(--sticker-text-tomate-verte);
}

.category-bg-08 {
    background-color: var(--sticker-bg-tomate-multicolore);
}

.category-text-08 {
    color: var(--sticker-text-tomate-multicolore);
}

.category-08 {
    background-color: var(--sticker-bg-tomate-multicolore);
    color: var(--sticker-text-tomate-multicolore);
}

.category-bg-09 {
    background-color: var(--sticker-bg-courgette);
}

.category-text-09 {
    color: var(--sticker-text-courgette);
}

.category-09 {
    background-color: var(--sticker-bg-courgette);
    color: var(--sticker-text-courgette);
}

.category-bg-10 {
    background-color: var(--sticker-bg-concombre);
}

.category-text-10 {
    color: var(--sticker-text-concombre);
}

.category-10 {
    background-color: var(--sticker-bg-concombre);
    color: var(--sticker-text-concombre);
}

.category-bg-11 {
    background-color: var(--sticker-bg-aromatiques);
}

.category-text-11 {
    color: var(--sticker-text-aromatiques);
}

.category-11 {
    background-color: var(--sticker-bg-aromatiques);
    color: var(--sticker-text-aromatiques);
}

label {
    margin-right: 0.5rem;
    font-weight: 600;
}

.fieldset {
    display: flex;
    padding: 0.2rem 0.1rem;
}

.fieldset label {
    width: 30%;
    text-align: right;
}

.fieldset span {
    text-align: left;
}

.App {
    text-align: center;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}

.App-logo {
    height: 8rem;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 8rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    color: white;
    padding: 0 2rem;
    justify-content: space-between;
    border-bottom: 0.5px solid #3f5943;
}

.App-header .logo-title-group {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    gap: 2rem;
}

.App-header .titles {
    display: flex;
    flex-direction: column;
}


.App-header .site-title {
    color: var(--logo-green);
    font-weight: 300;
    font-size: 3rem;
    font-style: oblique;
}

.App-header .title {
    font-size: 2rem;
}

.header-2 {
    background-color: #282c34;
    padding: 1rem;
}

.slogan {
    color: #aed6af;
    font-weight: 300;
    font-style: italic;
    font-size: 1.5rem;
    padding: 0.2rem;
}
.slogan a,.slogan a:visited {
    color: #aed6af;
    font-weight: 400;
    font-style: italic;
    font-size: 1.5rem;
    padding: 0.2rem;
    text-decoration: underline;
}

.contact {
    display: flex;
    flex-direction: column;
    width: 25rem;
    border: 0.5px solid #8f9c8c;
    color: var(--contact-color);
    background-color: var(--contact-bg);
    font-size: 1.2rem;
}

.contact a {
    color: var(--contact-color);
    margin-left: 0.2rem;
}

.contact .bon_commande {
    background-color: #585958ba;
    color: #d7dcd5;
    margin: 0.3rem 0 0;
    padding: 0.3rem;
}

.App-link {
    color: #61dafb;
}

.pastille {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
    }
}

main {
    display: flex;
    flex-direction: column;
    padding: 0.2vh 0;
}


.catalog {
    background-color: var(--catalog-bg);
    border: #c2e1d0 1px solid;
    margin: 0.3rem;
}

.categories-navigation {
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    flex-wrap: wrap;
    padding: 0.3rem;
}

.categories-navigation .category-nav-item {
    padding: 0.2rem 0.3rem;
    background-color: rgb(16 177 3);
    color: #e5ffec;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 6rem;
}

.categories-navigation .category-nav-item .bold {
    font-weight: 800;
}

.category-variant {
    padding-left: 0.8rem;
}

.catalog-list-category {
    margin-bottom: 0.2rem;
}

.catalog-list-category-header {
    display: flex;
    padding: 0.2rem 0rem;
    border-bottom: 1px solid rgb(10 161 10);
    color: rgb(10 161 10);
    align-items: center;
    margin-right: 5rem;
    background-color: #f6faf2;
}

.catalog-list-category.collpased .catalog-list-category-header {
    width: 50%;
}

.catalog-list-category.collpased .catalog-list-category-header .catalog-list-category-title {
    font-weight: 300;
    font-style: normal;
}

.catalog-list-category-header .pastille {
    margin-right: 0.8rem;
    margin-left: 0.3rem;
    cursor: pointer;
}

.catalog-list-category-title {
    font-style: italic;
    font-weight: 200;
    font-size: 1.5rem;
    text-transform: uppercase;
    border-bottom: 1px solid #f5f8f6;
    cursor: pointer;
    display: flex;
    align-items: start;
}


.catalog-list-category-title .bold {
    font-weight: 800;
}

.catalog-list-detail {
    display: flex;
    flex-direction: row;
}

.catalog-detail {
    width: 50%;
    margin-right: 1rem;
    display: none;
}

.catalog-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 3vh 4vw;
    gap: 1rem;
    justify-content: center;
}


.catalog-list__item-plan {
    height: 30rem;
    width: 23%;
    box-shadow: 0px 0px 3px #6b796a;
    background-color: var(--card-bg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.catalog-list__item-plan .detailed,
.catalog-list__item-plan .catalog-list__item-plan_body .labeled-info.detailed{
    display: none;
}
.catalog-list__item-plan.detail .detailed ,
.catalog-list__item-plan.detail .catalog-list__item-plan_body .labeled-info.detailed{
    display: flex;
}
.catalog-list__item-plan.detail .catalog-list__item-plan_body .labeled-info.fruit{
    height: auto;
}
.catalog-list__item-plan.detail .catalog-list__item-plan_body .description{
    gap:0.8rem;
}

.catalog-list__item-plan.detail .catalog-list__item-plan_body .labeled-info .title {
    min-width: 6rem;
}

.catalog-list__item-plan.detail {
    width: 37rem;
    height: 40rem;
}


.catalog-list__item-plan.selected {
    color: red;
    border: 1px solid red;
}

.catalog-list__item-plan_title {
    font-weight: 800;
    font-size: 1.2rem;
    padding: 0.5rem 0.5rem;
    min-height: 2.7rem;
    background-color: #ade5ad;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hide {
    display: none;
}

.catalog-list__item-plan_title .card-title {
    width: 85%;
    color: #3d6239;
}

.catalog-list__item-plan_body {
    display: flex;
    flex-direction: column;
    padding: 0;
    flex-grow: 2;
    height: 65%;
    gap: 0.2rem;
}

.catalog-list__item-plan_body .photo {
    height: 40%;
    overflow: hidden;
}

.catalog-list__item-plan_body .photo img {
    width: 100%;
}

.catalog-list__item-plan_body .description {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 60%;
}

.catalog-list__item-plan_body .labeled-info .value {
    overflow: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;/* Firefox */
}
.catalog-list__item-plan_body .labeled-info .value::-webkit-scrollbar {
    display: none;/* Safari and Chrome */
}


.catalog-list__item-plan_body .labeled-info.fruit {
    height: 60%;
}

.catalog-list__item-plan_body .labeled-info.saveur {

}

.catalog-list__item-plan_body .labeled-info {
    display: flex;
}

.catalog-list__item-plan_body .labeled-info .title {
    min-width: 4rem;
    text-align: right;
    border-right: 0px solid black;
}

.catalog-list__item-plan_body .labeled-info .value {
    text-align: start;
    margin: 0 0.3rem;
}

.catalog-list__item-plan_footer {
    display: flex;
    font-weight: 600;
    border-top: 1px solid black;
    justify-content: space-between;
    padding: 0.5rem;
}

.catalog-detail {
    border: 1px solid black;
    box-shadow: 0px 0px 2px #1d3b1b;
    background-color: var(--card-bg);
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

.plant-form {
    width: 75%;
}

.plant-form button {
    border: 0px;
    padding: 2rem;
    font-size: 1.2rem;
}

.plant-form .field {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
}

.plant-form textarea {
    padding: 10px;
    width: 100%;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px #999;
}

.plant-form label,
.plant-form textarea {
    font-size: 0.8rem;
    letter-spacing: 1px;
}

.message {
    font-size: 1.5rem;
    color: #7E0021;
    display: flex;
    align-items: center;
    justify-content: center;
}
.message .agenda {
    background-color: rgba(235, 245, 232, 0.85);
    width: 250px;
    height: 300px;
    margin: 0;
    overflow: scroll;
    font-size: 1rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0;
    justify-content: space-between;
}
.message .message-left h2{
    margin: 0;
    width: 100%;
    background-color: rgba(188, 217, 180, 0.93);
    font-size: 2rem;
}
.message .agenda .agenda-jour{
    font-weight: bolder;

}

.message .agenda li {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.message .message-left {
    margin: 10px;
    max-height: 350px;
    padding:0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
}
.message .message-left , .message .message-left h2, .message .message-left .agenda{
    display: none;
}
.message p {
    padding: 0 2rem;
}

@media (max-width: 768px) {
    .App-header {
        padding: 0rem 1rem 0.3rem;

    }

    .App-logo {
        height: 5rem;
        pointer-events: none;
    }


    .App-header .title {
        font-size: 2rem;
    }

    .App-header .site-title {
        font-size: 3rem;
        padding: 0;
    }


    main {
        padding: 0.2vh 0;
    }
    .message {
        flex-direction: column;
    }
    .message .agenda {
        height: 300px;
        margin: 0;
    }
    .message img {
        display: none;
    }
    .message .message-left {
    }

    .catalog-list__item-plan {
        width: 48%;
    }

}

@media (max-width: 576px) {

    .App-header {
        flex-direction: column;

    }

    .App-header .logo-title-group {
        padding: 0.5rem 0;
        gap: 0;
        border-bottom: 0.5px solid #3f5943;
    }

    .App-header .contact {
        border: 0;
    }

    .header-2 {
        padding: 0.2rem;
    }

    .header-2 .slogan {
        font-size: 1.2rem;
    }

    .catalog-list__item-plan {
        width: 100%;
    }


    .catalog-list-category-title {
        flex-direction: column;
    }

    .catalog-list__item-plan {
        height: auto;
    }

    .catalog-list__item-plan_body .labeled-info.fruit {
        height: auto;
    }

    .catalog-list__item-plan_body .labeled-info.saveur {
        height: auto;
    }

    .catalog-list-category.collpased .catalog-list-category-header {
        width: 100%
    }
}




@media (max-width: 400px) {
    .App-logo {
        height: 4rem;
        pointer-events: none;
    }
}
